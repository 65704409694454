import { resolveUnref } from '@vueuse/core'

import { type RequestReturn } from '@shopify/shopify-api'

import { type ShopifyGraphQLResponse } from '@/types/shopify'

import { type ProductConnectionArgs } from '@/graphql'

import { type Product, type Products } from '@/types'

/**
 * 
 * useProducts
 * 
 * @param params 
 * @returns 
 */
export const useProducts = (params: ProductConnectionArgs) => {
  const products = ref<Product[]>([])

  const fetchProducts = async () => {
    const {
      after,
      before,
      first = 10,
      last = -1,
      sortKey = 'CREATED_AT',
      reverse = true,
    } = params

    const query = computed(() => {
      return resolveUnref(params.query)
    })

    const key = computed(() => {
      const query = resolveUnref(params.query)

      const search = { after, before, first, last, sortKey, reverse }

      if (query) {
        return `products-${JSON.stringify(search)}-${JSON.stringify(query)}`
      }

      return `products-${JSON.stringify(search)}`
    })

    const { data } = await useFetch<RequestReturn<ShopifyGraphQLResponse<Products>>>(`/api/products`,
      {
        query: {
          after,
          before,
          first,
          last: last === -1 ? undefined : last,
          sortKey,
          reverse,
          query: query.value,
        },
        key: key.value,
      },
    )

    if (!data.value) return

    products.value = data.value.body.data.products.edges.map(edge => edge.node)
  }

  return {
    products,
    fetchProducts,
  }
}